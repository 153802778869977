import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

const fetchTemplateCollections = async () => {
    const { data } = await window.axios.get(route('collection.paginate', {
        type: 'templates',
        parents: 1,
        subs: 1,
        active: 1,
        per_page: 200
    }));
    return data.data;
};

const useTemplateCollections = () => {
    const queryClient = useQueryClient();

    const { data: collections, isLoading, error } = useQuery({
        queryKey: ['templateCollections'],
        queryFn: fetchTemplateCollections,
    });

    useEffect(() => {
        const channel = window.Echo.channel(`templates-collections`);
        channel.listen('.templates-collections-updated-event', () => {
            queryClient.invalidateQueries(['templateCollections']);
        });

        // Cleanup on component unmount
        return () => {
            channel.stopListening('.templates-collections-updated-event');
            window.Echo.leaveChannel(`templates-collections`);
        };
    }, [queryClient]);

    return { collections: collections || [], isLoading, error };
};

const fetchProductCollections = async () => {
    const { data } = await window.axios.get(route('collection.paginate', {
        type: 'predesign-products',
        parents: 1,
        subs: 1,
        active: 1,
        per_page: 200
    }));
    return data.data;
};

const useProductCollections = () => {
    const queryClient = useQueryClient();

    const { data: collections, isLoading, error } = useQuery({
        queryKey: ['productCollections'],
        queryFn: fetchProductCollections,
    });

    useEffect(() => {
        const channel = window.Echo.channel(`products-collections`);
        channel.listen('.products-collections-updated-event', () => {
            queryClient.invalidateQueries(['productCollections']);
        });

        // Cleanup on component unmount
        return () => {
            channel.stopListening('.products-collections-updated-event');
            window.Echo.leaveChannel(`products-collections`);
        };
    }, [queryClient]);

    return { collections, isLoading, error };
};

export { useProductCollections, useTemplateCollections };

